// Mendapatkan semua cookie
const cookies = document.cookie;

// Ekstrak token
export const getCookieValue = (name: string) => {
  const value = `; ${cookies}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) return parts.pop()?.split(';').shift();
};

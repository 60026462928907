import { getBaseUrlCompany } from '@/helpers';
import { useEffect } from 'react';

export default function Mobile() {
  useEffect(() => {
    // Replace current URL
    window.location.replace(`${getBaseUrlCompany()}/home`);
  }, []);

  return null;
}

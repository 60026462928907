import { rtkInfiniteScrollConfig } from '@/config/api/infiniteScroll';
import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { TCrewApplicantStatus, TCrewRate } from '@/interfaces/crew/detail';
import { IApplicationListItem, TApplicationStatus } from '@/interfaces/jobs/application';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IReturnGetJobDetailApplicationList,
  IRequestParamGetJobDetailApplicationList,
  IResponseGetJobDetailApplicationList,
  IRequestShortlist,
  IRequestJobOffer,
  IRequestInviteInterview,
  IReturnGetJobDetailApplication,
  IReturnShortlist,
} from './type';

export const jobsApplicationApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'jobsApplicationApi',
  tagTypes: ['JOBS_APPLICANT', 'JOBS_APPLICANTS'],
  endpoints: builder => ({
    getJobDetailApplicationList: builder.query<
      IReturnGetJobDetailApplicationList,
      IRequestParamGetJobDetailApplicationList
    >({
      query: ({
        id,
        page,
        applicantStatus,
        crewStatus,
        sortByAppliedDate,
        sortByAvailabilityDate,
        activeTab,
      }) => ({
        url: `/v1/company/job/applied`,
        params: {
          jobId: id,
          page,
          applicantStatus,
          crewStatus,
          sortByAppliedDate,
          sortByAvailabilityDate,
          activeTab: activeTab,
        },
        method: 'GET',
      }),
      providesTags: ['JOBS_APPLICANTS'],
      transformResponse: (response: IResponseGetJobDetailApplicationList) => {
        const list: IApplicationListItem[] =
          response.items?.map(el => ({
            id: el.id,
            crewId: el.crewId,
            name: el.name,
            verification: {
              status: el.verification?.status,
            },
            applicationStatus: el.applicationStatus as TApplicationStatus,
            lastRank: {
              rank: el.lastRank?.rank || '-',
              yearExperience: 0,
              monthExperience: 0,
            },
            lastVesselType: el.lastVesselType,
            appliedAt: el.appliedAt,
            applicantStatus: el.applicantStatus as TCrewApplicantStatus,
            country: el.country,
            birthday: el.birthday,
            rate: el.rate as TCrewRate,
            avatar: el.avatar,
            deletedAt: el.deletedAt,
            companyColorGroup: el.companyColorGroup,
            expiredAt: el.expiredAt,
          })) || [];

        return {
          pagination: response.meta,
          list,
        };
      },
      merge: rtkInfiniteScrollConfig.merge,
      serializeQueryArgs: rtkInfiniteScrollConfig.serializeQueryArgs,
      forceRefetch: rtkInfiniteScrollConfig.forceRefetch,
    }),
    getJobDetailApplicationCrew: builder.query<
      IReturnGetJobDetailApplication,
      { jobId: string; crewId: string }
    >({
      query: ({ jobId, crewId }) => ({
        url: `/v1/company/applied-job/${jobId}/crew/${crewId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['JOBS_APPLICANT'],
    }),
    shortlist: builder.mutation<IReturnShortlist, IRequestShortlist>({
      query: data => ({
        url: `/v1/company/shortlist`,
        method: 'PATCH',
        body: data,
      }),
    }),
    jobOffer: builder.mutation<void, IRequestJobOffer>({
      query: data => ({
        url: `/v1/company/job-offer`,
        method: 'PATCH',
        body: data,
      }),
    }),
    inviteInterview: builder.mutation<void, IRequestInviteInterview>({
      query: data => ({
        url: '/v1/company/invite-interview',
        method: 'PATCH',
        body: data,
      }),
    }),
    jobFinalOffer: builder.mutation<void, FormData>({
      query: data => ({
        url: '/v1/company/job-final-offer',
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetJobDetailApplicationListQuery,
  useLazyGetJobDetailApplicationCrewQuery,
  useGetJobDetailApplicationCrewQuery,
  useShortlistMutation,
  useJobOfferMutation,
  useInviteInterviewMutation,
  useJobFinalOfferMutation,
} = jobsApplicationApi;

import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { getGenderValueFromResponse } from '@/helpers/gender';
import { IUserData } from '@/interfaces/user';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IResponseUserData,
  IRequestSetupUser,
  IRequestRegisterUsers,
  IRequestEditUserInfo,
  IRequestValidateOnboardingToken,
  IReturnValidateOnboardingToken,
  IRequestChangePassword,
  IRequestResetPassword,
} from './type';
import { setUserData } from '@/store/slices/auth';

export const authUserApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'authUserApi',
  tagTypes: ['user-info'],
  endpoints: builder => ({
    // this function is used for initiate get user data with token in authentication process
    getUserWithToken: builder.query<IUserData, string>({
      query: token => ({
        url: '/v1/company-user',
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['user-info'],
      transformResponse: (response: IResponseUserData): IUserData => ({
        ...response,
        avatar: response.pictureURL || '',
        id: response.id || '',
        email: response.email || '',
        name: response.name || '',
        role: response.role.value || '',
        gender: getGenderValueFromResponse(response.isMale),
        jobTitle: response.position || '',
        phoneNumber: response.phoneNumber || '',
        status: response.status || null,
        accesses: response.accesses || [],
        isShowOnBoarding: response.isShowOnBoarding || false,
      }),
    }),
    getUser: builder.query<IUserData, void>({
      query: () => ({
        url: '/v1/company-user',
        method: 'GET',
      }),
      transformResponse: (response: IResponseUserData): IUserData => ({
        ...response,
        avatar: response.pictureURL || '',
        id: response.id || '',
        email: response.email || '',
        name: response.name || '',
        role: response.role.value || '',
        gender: getGenderValueFromResponse(response.isMale),
        jobTitle: response.position || '',
        phoneNumber: response.phoneNumber || '',
        status: response.status || null,
        accesses: response.accesses || [],
        isShowOnBoarding: response.isShowOnBoarding || false,
      }),
      providesTags: ['user-info'],
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data: userData } = await queryFulfilled;

          dispatch(setUserData(userData));
        } catch (error) {
          return;
        }
      },
    }),
    setupUser: builder.mutation<null, IRequestSetupUser>({
      query: body => ({
        url: '/v1/company-user/reset-password',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['user-info'],
    }),

    editUserInfo: builder.mutation<IUserData, IRequestEditUserInfo>({
      query: (payload: IRequestEditUserInfo) => ({
        url: '/v1/company-user',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['user-info'],
    }),
    registerUsers: builder.mutation<null, IRequestRegisterUsers>({
      query: ({ users }) => ({
        url: '/v1/company-user',
        method: 'POST',
        body: {
          users,
        },
      }),
    }),
    changePassword: builder.mutation<void, IRequestChangePassword>({
      query: ({ email, oldPassword, newPassword }) => ({
        // mock integration
        url: '/v1/company-user',
        method: 'PATCH',
        body: {
          oldEmail: email,
          oldPassword,
          password: newPassword,
        },
      }),
    }),
    validateOnboardingToken: builder.query<
      IReturnValidateOnboardingToken,
      IRequestValidateOnboardingToken
    >({
      query: ({ token, email }) => ({
        url: '/v1/company-user/reset-password',
        method: 'GET',
        params: {
          token,
          email,
        },
      }),
    }),
    sendForgotPassword: builder.mutation<void, { email: string; context: string }>({
      query: payload => ({
        url: '/v1/company-user/send-forgot-password',
        method: 'POST',
        body: {
          email: payload.email,
          context: payload.context,
        },
      }),
    }),
    resetPassword: builder.mutation<void, IRequestResetPassword>({
      query: body => ({
        url: '/v1/company-user/reset-password',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserWithTokenQuery,
  useSetupUserMutation,
  useRegisterUsersMutation,
  useEditUserInfoMutation,
  useChangePasswordMutation,
  useValidateOnboardingTokenQuery,
  useSendForgotPasswordMutation,
  useResetPasswordMutation,
} = authUserApi;

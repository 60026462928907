import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OneSignal from 'react-onesignal';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import { useLazyGetUserWithTokenQuery } from '@/api/auth/user';
import { IUserData } from '@/interfaces/user';
import { RootState } from '@/store';
import { setHasAuthenticated, setToken, setUserData } from '@/store/slices/auth';
import { setMixpanelUserData, track } from '@/helpers/track/tracker';
import { getCookieValue } from '@/helpers/cookies';

interface IProps {
  autoFetch?: boolean;
}

const logToLogrocket = (userData: IUserData) => {
  LogRocket.identify(userData.id, {
    name: userData.name || '',
    email: userData.email || '',
  });
};

const logToSentry = (userData: IUserData) => {
  Sentry.setTag('name', userData.name);
  Sentry.setTag('email', userData.email);
};

const logToMixpanel = (userData: IUserData) => {
  try {
    mixpanel.identify(userData.id);
    setMixpanelUserData('name', userData.name);
    setMixpanelUserData('email', userData.email);
  } catch (error) {
    console.error(error);
  }
};

export default function useCheckAuth(
  { autoFetch }: IProps = {
    autoFetch: true,
  }
) {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  const [getUserData] = useLazyGetUserWithTokenQuery();

  const tokenLocalStorage = useMemo(() => localStorage.getItem('token'), []);
  const tokenDevCookie = useMemo(() => getCookieValue('token-dev'), []);
  const tokenCookie = useMemo(() => getCookieValue('token'), []);

  const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';

  useEffect(() => {
    if (tokenLocalStorage) {
      dispatch(setToken(tokenLocalStorage));
    } else if (tokenCookie && !isDevelopment) {
      dispatch(setToken(tokenCookie));
    } else if (tokenDevCookie && isDevelopment) {
      dispatch(setToken(tokenDevCookie));
    } else {
      dispatch(setToken(''));
      dispatch(setHasAuthenticated(false));
    }
  }, [tokenLocalStorage, tokenCookie, tokenDevCookie]);

  const fetchUserData = async () => {
    if (!token) return;
    try {
      const userData = await getUserData(token).unwrap();
      track('auth-user_authenticated');

      logToLogrocket(userData);
      logToSentry(userData);
      logToMixpanel(userData);

      dispatch(setToken(token));
      dispatch(setUserData(userData));
      dispatch(setHasAuthenticated(true));
      // onesignal doesn't use await so when it failed it will not catched by try catch
      // and logout
      OneSignal.login(userData.id);
    } catch (error) {
      dispatch(setHasAuthenticated(false));
      // this error handler is for when the token is invalid
      // there's no need to show the error message if the token is invalid
      console.error(error);
    }
  };

  useEffect(() => {
    if (autoFetch) fetchUserData();
  }, [token]);

  return {
    fetchUserData,
  };
}
